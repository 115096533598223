import React from 'react';
import { Navbar, Divider } from '@blueprintjs/core';
import Logo from '../../assets/new-logo-icon.png';

function ErrorNavbar() {
  return (
    <React.Fragment>
      <Navbar className="navbar">
        <Navbar.Group>
          <Navbar.Heading
            className="navbar__logo__container"
            style={{ margin: '0.714em' }}
          >
            <img src={Logo} height={'32px'} width={'32px'} />
            <h3>Catalogue Studio</h3>
          </Navbar.Heading>
        </Navbar.Group>
      </Navbar>
      <Divider className="navbar__divider" />
    </React.Fragment>
  );
}

export default ErrorNavbar;
